<template>
	<el-container>
		<y-header class="show"></y-header>
		<el-main>
			<div class="page-container">
				<img :src="$staticUrl + '/images/ico-success.png'" class="success-icon" />
				<div class="fc-c" v-if="!isLogin">
					<span class="success-text">我们已登记您的意向，稍候3个工作日内您的专属客户经理会联系您，并深入了解您的需求，为您定制更加契合的解决方案</span>
					<el-button type="primary" @click="back">返回首页</el-button>
				</div>
				<div v-else>
					<span class="success-title">欢迎您，杨先生</span>
					<span class="success-text">您可以点击左边的按钮登录试用体验悠租云，也可以点击右边的按钮开始您的商户配置，配置完成后即可正式开店</span>
					<div class="fr-c">
						<el-button type="cancel" @click="dialogVisible = true">开始试用</el-button>
						<el-button type="primary" @click="setting">开始配置我的商户</el-button>
					</div>
				</div>
			</div>
		</el-main>
		<y-footer class="show"></y-footer>
		<el-dialog title="请选择试用的角色" :visible.sync="dialogVisible" width="30%" center>
			<el-radio-group v-model="radio" style="padding: 0 6rem">
				<el-radio :label="1">管家</el-radio>
				<el-radio :label="2">店长</el-radio>
				<el-radio :label="3">财务</el-radio>
				<el-radio :label="4">管理者</el-radio>
			</el-radio-group>
			<span slot="footer" class="dialog-footer">
				<!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</el-container>
</template>
<script>
export default {
	data() {
		return {
			isLogin: true,
			dialogVisible: false,
			radio: 1
		}
	},
	created() {},
	mounted() {},
	methods: {}
}
</script>
<style lang="scss" scoped>
.page-container {
	height: 63rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 1024px) {
		width: 100vw;
		height: 100vh;
		min-width: 0;
	}
}
.show {
	display: block;
	@media screen and (max-width: 1024px) {
		display: none;
	}
}
.success-icon {
	width: 10.4rem;
	height: 10.4rem;
	margin-bottom: 2.8rem;
}
.success-text {
	color: #666666;
	width: 46rem;
	text-align: center;
	display: flex;
	align-items: center;
	margin: 1.2rem 0 5rem;
	@media screen and (max-width: 1024px) {
		width: 35rem;
	}
}
.success-title {
	color: #333333;
	font-size: 2rem;
	font-weight: bold;
	display: flex;
	justify-content: center;
}
</style>
